
import { Component, Vue } from 'vue-property-decorator';
import KokiFloodReleaseSupportInfo from '@/components/rightContents/KokiFloodReleaseSupportInfo.vue';
import { useSysInfoStore } from '@/storeV2/sysInfo';
import DamSupport from '../DamSupport.vue';
import DamTable from '../DamTable.vue';
import DamDischargeInfoTable from '../DamDischargeInfoTable.vue';
import RChart from '../../molecules/RChart.vue';
import RyuryoChart from '../../molecules/RyuryoChart.vue';

@Component({
  components: {
    DamSupport,
    DamTable,
    DamDischargeInfoTable,
    KokiFloodReleaseSupportInfo,
    RChart,
    RyuryoChart,
  },
})
export default class DamPredictionMobile extends Vue {
  sysInfoStore = useSysInfoStore();

  get dischargeInfo() {
    return this['$store'].getters.dischargeInfo;
  }

  get result() {
    return this['$store'].getters.result;
  }

  get basinId() {
    return this['$store'].getters.basinId;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  get targetTime() {
    return this['$store'].getters.formatTargetTime;
  }

  get stationName() {
    const { stationsMap } = this['$store'].getters;
    const { stationId } = this['$store'].getters;
    if (!stationsMap || !stationId) {
      return '';
    }
    return stationsMap[stationId].name;
  }

  get type() {
    return this['$store'].getters.type;
  }

  get figure() {
    return this.sysInfoStore.stationsMap[this.stationId]?.figure ?? '';
  }

  get displayTargetTime() {
    const { formatTargetTime } = this['$store'].getters;
    if (!formatTargetTime) {
      return '';
    }
    return `${formatTargetTime.slice(0, 4)}年${formatTargetTime.slice(4, 6)}月${formatTargetTime.slice(6, 8)}日`
        + ` ${formatTargetTime.slice(8, 10)}時${formatTargetTime.slice(10, 12)}分`;
  }
}
