import { use01NaganoEns } from '@/functions';
import Repository from './Repository';

const resource = 'api/lastTime';

export default {
  get({ pathParams, queryParams }:
    {
      pathParams: {
        basinId: string,
        stationId: string,
      },
      queryParams: {
        isEn?: boolean,
      }
    }): Promise<any> {
    return Repository.get(
      `${resource}/${
        use01NaganoEns(queryParams.isEn) ? '01nagano_ens' : pathParams.basinId
      }/${pathParams.stationId}?isEn=${queryParams.isEn ?? false}`,
    );
  },
};
