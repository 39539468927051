export const GetBaseUrl = (): string => (document.domain === 'localhost' ? process.env.VUE_APP_BASE_URL : `${window.location.protocol}//${document.domain}`);

export const use01NaganoEns = (isEn) => {
  const { domain } = document;
  // アンサンブルデータ取得の際に特別にbasinIdとして「01nangano_ens」を利用する環境
  return isEn && [
    'demo.stage.damsys.riskma.net',
    'nagano-pref.stage.damsys.riskma.net',
    'nagano-pref.damsys.riskma.net',
    'nagano-pref2.damsys.riskma.net']
    .includes(domain);
};

export const getMaxScale = (maxNum) => {
  let dR = 5;
  if (maxNum > 50) dR = 10;
  if (maxNum > 100) dR = 50;
  if (maxNum > 500) dR = 100;
  if (maxNum > 1000) dR = 500;
  return (Math.ceil(maxNum / dR)) * dR;
};

export const getCookieValue = (key): string | null => {
  const cookieValueArray = document.cookie.match(`(^|[^;]+)\\s*${key}\\s*=\\s*([^;]+)`);
  if (cookieValueArray) {
    const cookieValue = cookieValueArray.pop();
    if (cookieValue) return cookieValue;
  }
  return null;
};

export const deleteCookie = (key) => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

export const deleteAllCookie = () => {
  deleteCookie('accessToken');
  deleteCookie('idToken');
  deleteCookie('refreshToken');
  deleteCookie('defaultTab');
};
