import { GetBaseUrl } from '@/functions';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
];

routes.push(...[
  {
    path: '/',
    component: Home,
  },
  {
    path: '/login',
    component: Login,
  },
]);

const router = new VueRouter({
  base: GetBaseUrl(),
  routes,
});

export default router;
