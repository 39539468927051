
import { Component, Vue } from 'vue-property-decorator';
import EnRChart from '@/components/molecules/EnRChart.vue';
import EnRuikaChart from '@/components/molecules/EnRuikaChart.vue';
import EnChosuiChart from '@/components/molecules/EnChosuiChart.vue';
import EnRyuryoChart from '@/components/molecules/EnRyuryoChart.vue';
import GraphSetting from '../GraphSetting.vue';
import DamSupport from '../DamSupport.vue';

@Component({
  components: {
    GraphSetting,
    DamSupport,
    EnRChart,
    EnRuikaChart,
    EnChosuiChart,
    EnRyuryoChart,
  },
})
export default class DamEnsample extends Vue {
  get basinId() {
    return this['$store'].getters.basinId;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  get ensampleTabStatusStatus() {
    return this['$store'].getters.ensampleTabStatusStatus;
  }

  get stationName() {
    const { stationsMap } = this['$store'].getters;
    const { stationId } = this['$store'].getters;
    if (!stationsMap || !stationId) {
      return '';
    }

    return stationsMap[stationId].name === '松川ダム(ゲート放流)' ? '松川ダム' : stationsMap[stationId].name;
  }

  get type() {
    return this['$store'].getters.type;
  }

  get displayTargetTime() {
    const { enFormatTargetTime: formatTargetTime } = this['$store'].getters;
    if (!formatTargetTime) {
      return '';
    }
    return `${formatTargetTime.slice(0, 4)}年${formatTargetTime.slice(4, 6)}月${formatTargetTime.slice(6, 8)}日`
        + ` ${formatTargetTime.slice(8, 10)}時${formatTargetTime.slice(10, 12)}分`;
  }
}
