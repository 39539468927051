
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { TabIds } from '@/views/Home.vue';
import { getMaxScale } from '@/functions';
import BarChartComp from './BarChart';
import ChartMixin from './ChartMixin.vue';

@Component({
  components: {
    BarChartComp,
  },
})
export default class EnRChart extends Mixins(ChartMixin) {
  private chartOptions = null;

  get isMobile() {
    return this['$store'].getters.isMobile;
  }

  get tabId(): TabIds {
    return this['$store'].getters.tabId;
  }

  get rerender() {
    return this['$store'].getters.rerender;
  }

  set rerender(value) {
    this['$store'].commit('setRerender', value);
  }

  get gridPitch() {
    return this['$store'].getters.enGridPitch;
  }

  get kouuScales() {
    return this['$store'].getters.kouuScales;
  }

  get kouuScale() {
    return this['$store'].getters.kouuScale === '自動' ? this['$store'].getters.kouuAutoScale : this['$store'].getters.kouuScale;
  }

  get targetTime() {
    return this['$store'].getters.enTargetTime;
  }

  get yskSpan() {
    return this['$store'].getters.enYskSpan;
  }

  get ensampleDisplayMember() {
    return this['$store'].getters.ensampleDisplayMember;
  }

  mounted() {
    this.updateResult();
  }

  getOneFromResult(result, key) {
    return result.map((obj) => (Math.floor(obj[key]) <= -999 ? null : obj[key]));
  }

  @Watch('tab')
  @Watch('yskSpan')
  @Watch('rerender')
  @Watch('enResult')
  @Watch('ensampleDisplayMember')
  public async updateResult(): Promise<void> {
    if (this.tabId !== 'ensembleCalculation') return;

    const top = this.getOneFromResult(this.enResult[0], 'R');
    const middle = this.getOneFromResult(this.enResult[Math.floor(this.enResult.length / 2)], 'R');
    const bottom = this.getOneFromResult(this.enResult[this.enResult.length - 1], 'R');

    const dataTimeArray = this.enResult[0].map((obj) => obj.datetime);

    const maxNumber = Math.max(5, ...top, ...middle, ...bottom);

    this['$store'].commit('setKouuAutoScale', getMaxScale(maxNumber));

    this.chartData = {
      labels: dataTimeArray.slice(0, 7 + this.yskSpan),
      datasets: [
        {
          label: '上位 流域平均雨量',
          data: top.slice(0, 7 + this.yskSpan),
          borderWidth: 1,
          borderColor: 'red',
          style: 'border-top-color: red;',
          fixed: true,
        },
        {
          label: '中位 流域平均雨量',
          data: middle.slice(0, 7 + this.yskSpan),
          borderWidth: 1,
          borderColor: 'blue',
          style: 'border-top-color: blue;',
          fixed: true,
        },
        {
          label: '下位 流域平均雨量',
          data: bottom.slice(0, 7 + this.yskSpan),
          borderWidth: 1,
          borderColor: 'green',
          style: 'border-top-color: green;',
          fixed: true,
        },
      ],
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem, obj) => `${obj.datasets[tooltipItem.datasetIndex].label}:${Number(tooltipItem.value).toFixed(1)}`,
        },
      },
      legend: {
        labels: {
          filter: () => false,
        },
      },
      annotation: {
        annotations: [{
          type: 'line',
          scaleID: 'x-axis-0',
          value: this.targetTime,
          borderWidth: 2,
          borderColor: 'gray',
        }],
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'HH:mm',
              },
              stepSize: this.gridPitch,
            },
            gridLines: {
              color: '#999',
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
          },
          {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MM/DD',
              },
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              fontSize: 14,
              fontColor: 'black',
            },
            gridLines: {
              color: '#999',
              drawTicks: false,
              drawBorder: false,
              drawOnChartArea: false,
              zeroLineColor: 'rgba(0, 0, 0, 0.1)',
            },
          },
        ],
        yAxes: [
          {
            afterFit(scaleInstance) {
              // eslint-disable-next-line no-param-reassign
              scaleInstance.width = 40;
            },
            gridLines: {
              color: '#999',
            },
            ticks: {
              // maxTicksLimit: 6,
              // min: 0,
              max: this.kouuScale,
              // callback: (value) => {
              //   if (value % 1 !== 0) return null;
              //   const n = value % (this.kouuScale / 5);
              //   return n === 0 ? value : null;
              // },
              stepSize: (this.kouuScale / 5),
              fontSize: 14,
              fontColor: 'black',
            },
          },
          {
            position: 'right',
            display: true,
            gridLines: {
              color: '#999',
            },
            ticks: {
              callback() { return ''; },
              fontSize: 14,
              fontColor: 'black',
            },
          },
        ],
      },
    };
  }
}
