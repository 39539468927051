
import { Component, Mixins, Watch } from 'vue-property-decorator';
import BarChart from './BarChart';
import ChartMixin from './ChartMixin.vue';

@Component({
  components: {
    BarChart,
  },
})
export default class RyuryoChart extends Mixins(ChartMixin) {
  private chartOptions = null;

  get colors() {
    return this['$store'].getters.colors;
  }

  get loading() {
    return this['$store'].getters.loading;
  }

  get rerender() {
    return this['$store'].getters.rerender;
  }

  set rerender(value) {
    this['$store'].commit('setRerender', value);
  }

  get gridPitch() {
    return this['$store'].getters.gridPitch;
  }

  get maxQScales() {
    return this['$store'].getters.maxQScales;
  }

  get maxQScale() {
    return this['$store'].getters.maxQScale === '自動' ? this['$store'].getters.maxQAutoScale : this['$store'].getters.maxQScale;
  }

  get minHScale() {
    return this['$store'].getters.minHScale === '自動' ? this['$store'].getters.minHAutoScale : this['$store'].getters.minHScale;
  }

  get maxHScale() {
    return this['$store'].getters.maxHScale === '自動' ? this['$store'].getters.maxHAutoScale : this['$store'].getters.maxHScale;
  }

  get targetTime() {
    return this['$store'].getters.targetTime;
  }

  get yskSpan() {
    return this['$store'].getters.yskSpan;
  }

  get autoQStep() {
    const { stationsMap } = this['$store'].getters;
    return stationsMap[this.stationId].scales.autoQStep;
  }

  get autoHStep() {
    const { stationsMap } = this['$store'].getters;
    return stationsMap[this.stationId].scales.autoHStep;
  }

  get kurihashiKakuhoMap() {
    return this['$store'].getters.kurihashiKakuhoMap;
  }

  public getLimitLinesDatasets(): any[] {
    const { stationsMap } = this['$store'].getters;
    const datasets = stationsMap[this.stationId].limitLines.map((line) => {
      const data: any = [];
      const dataTimeArray = this.getOneFromSimResult('datetime');
      dataTimeArray.slice(0, 37 + 6 * this['$store'].getters.yskSpan).forEach((dataTime) => {
        data.push(null);
      });

      line.details.forEach((detail) => {
        const startDate = new Date(this['$store'].getters.targetTime.replace(/-/g, '/'));
        const endDate = new Date(this['$store'].getters.targetTime.replace(/-/g, '/'));

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        startDate.setMonth(detail.start.split('/')[0] - 1);
        startDate.setDate(detail.start.split('/')[1]);
        endDate.setMonth(detail.end.split('/')[0] - 1);
        endDate.setDate(detail.end.split('/')[1]);
        if (startDate > endDate) {
          endDate.setFullYear(endDate.getFullYear() + 1);
        }
        dataTimeArray.slice(0, 37 + 6 * this['$store'].getters.yskSpan).forEach((dataTime, index) => {
          const targetData = new Date(dataTime.replace(/-/g, '/'));

          let updateValue = false;
          if (startDate <= targetData && targetData <= endDate) {
            updateValue = true;
            data[index] = detail.value;
          }
          if (!updateValue) {
            startDate.setFullYear(startDate.getFullYear() - 1);
            endDate.setFullYear(endDate.getFullYear() - 1);
            if (startDate <= targetData && targetData <= endDate) {
              updateValue = true;
              data[index] = detail.value;
            }
            startDate.setFullYear(startDate.getFullYear() + 1);
            endDate.setFullYear(endDate.getFullYear() + 1);
          }
          if (!updateValue) {
            startDate.setFullYear(startDate.getFullYear() + 1);
            endDate.setFullYear(endDate.getFullYear() + 1);
            if (startDate <= targetData && targetData <= endDate) {
              data[index] = detail.value;
            }
            startDate.setFullYear(startDate.getFullYear() - 1);
            endDate.setFullYear(endDate.getFullYear() - 1);
          }
        });
      });
      let showUsageGuide = false;
      if (line.name === '洪水量' || (line.yaxis && line.yaxis === 2)) {
        data.forEach((val) => {
          if (val && val >= 0 && val <= this.maxQScale) {
            showUsageGuide = true;
          }
        });
        return {
          type: 'line',
          label: line.name,
          data,
          fill: false,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: line.color,
          borderDash: [5, 3],
          style: `border-top-color: ${line.color};border-top-style: dotted;`,
          showUsageGuide,
          yAxisID: 'y-axis-2',
        };
      }

      data.forEach((val) => {
        if (val && this.minHScale <= val && val <= this.maxHScale) {
          showUsageGuide = true;
        }
      });

      return {
        type: 'line',
        label: line.name,
        data,
        fill: false,
        pointRadius: 0,
        borderWidth: 2,
        borderColor: line.color,
        borderDash: [5, 3],
        style: `border-top-color: ${line.color};border-top-style: dotted;`,
        showUsageGuide,
        yAxisID: 'y-axis-1',
      };
    });
    return datasets;
  }

  public setOptions() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem, obj) => `${obj.datasets[tooltipItem.datasetIndex].label}:${Number(tooltipItem.value).toFixed(2)}`,
        },
      },
      legend: {
        labels: {
          filter: () => false,
        },
      },
      annotation: {
        annotations: [{
          type: 'line',
          scaleID: 'x-axis-0',
          value: this.targetTime,
          borderWidth: 2,
          borderColor: 'gray',
        }],
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'HH:mm',
              },
              stepSize: this.gridPitch,
            },
            gridLines: {
              color: '#999',
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
          },
          {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MM/DD',
              },
            },
            gridLines: {
              drawTicks: false,
              drawBorder: false,
              drawOnChartArea: false,
              zeroLineColor: 'rgba(0, 0, 0, 0.1)',
              color: '#999',
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
          },
        ],
        yAxes: [
          {
            afterFit(scaleInstance) {
              // eslint-disable-next-line no-param-reassign
              scaleInstance.width = 50;
            },
            id: 'y-axis-1',
            type: 'linear',
            position: 'left',
            display: true,
            afterBuildTicks: (scale) => {
              let num = this.maxHScale;
              // eslint-disable-next-line no-param-reassign
              scale.ticks = [num];
              const step = (this.maxHScale - this.minHScale) / 10;
              while (scale.ticks.length < 11) {
                num -= step;
                // eslint-disable-next-line no-param-reassign
                scale.ticks.push(num);
              }
            },
            gridLines: {
              color: '#999',
            },
            ticks: {
              callback: (value: any) => value.toFixed(1),
              stepSize: 0.1,
              min: this.minHScale,
              max: this.maxHScale,
              fontSize: 14,
              fontColor: 'black',
            },
          },
          {
            afterFit(scaleInstance) {
              // eslint-disable-next-line no-param-reassign
              scaleInstance.width = 50;
            },
            id: 'y-axis-2',
            type: 'linear',
            position: 'right',
            display: true,
            gridLines: {
              color: '#999',
            },
            ticks: {
              callback: (value: any) => value.toFixed(1),
              // 10段階
              stepSize: this.maxQScale / 10,
              min: 0,
              max: this.maxQScale,
              fontSize: 14,
              fontColor: 'black',
            },
          },
        ],
      },
    };
  }

  getOneFromSimResult(key) {
    return this.result.sim_result.map((obj) => (Math.floor(obj[key]) <= -999 ? null : obj[key])).slice(0, 37 + 6 * this['$store'].getters.yskSpan);
  }

  @Watch('result')
  @Watch('yskSpan')
  @Watch('rerender')
  public async updateResult() {
    const type = Number(this['$store'].getters.type);
    const dataTimeArray = this.getOneFromSimResult('datetime');
    if (type === 7) {
      const QInAllArray = this.getOneFromSimResult('Qin_all');
      const QOutAllArray = this.getOneFromSimResult('Qout_all');
      const preStageArray = this.getOneFromSimResult('preStage');
      const wlevelArray = this.getOneFromSimResult('wlevel');
      this['$store'].commit('setMaxQAutoScale', Math.ceil(Math.max(...QInAllArray, ...QOutAllArray) / this.autoQStep) * this.autoQStep * 2);

      this['$store'].commit('setMaxHAutoScale', Math.ceil(Math.max(...wlevelArray.filter((v) => v > 0)) / this.autoHStep) * this.autoHStep);
      this['$store'].commit('setMinHAutoScale', Math.floor(Math.min(...wlevelArray.filter((v) => v > 0)) / this.autoHStep) * this.autoHStep);
      const { maxHAutoScale, minHAutoScale } = this['$store'].getters;
      if (Math.abs(maxHAutoScale - minHAutoScale) < this.autoHStep) {
        this['$store'].commit('setMaxHAutoScale', maxHAutoScale + this.autoHStep);
      }

      const showUsageGuideMap = {
        prestage: false,
        wlevel: false,
      };
      preStageArray.forEach((val) => {
        if (this.minHScale <= val && val >= this.minHScale) {
          showUsageGuideMap.prestage = true;
        }
      });
      wlevelArray.forEach((val) => {
        if (this.minHScale <= val && val >= this.minHScale) {
          showUsageGuideMap.wlevel = true;
        }
      });

      this.chartData = {
        labels: dataTimeArray.slice(0, 37 + 6 * this['$store'].getters.yskSpan),
        datasets: [
          {
            type: 'line',
            label: '貯水位',
            fill: false,
            data: wlevelArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: this.colors['貯水位']?.color ?? 'red',
            style: `border-top-color: ${this.colors['貯水位']?.color ?? 'red'};`,
            fixed: true,
            showUsageGuide: showUsageGuideMap.wlevel,
            yAxisID: 'y-axis-1',
          },
          {
            type: 'line',
            label: '洪水前目標水位',
            fill: false,
            data: preStageArray,
            pointRadius: 0,
            borderWidth: 4,
            borderDash: [2, 4],
            borderColor: 'blue',
            style: 'border-top-color: blue;border-top-style: dotted;',
            fixed: true,
            showUsageGuide: showUsageGuideMap.prestage,
            yAxisID: 'y-axis-1',
          },
          ...this.getLimitLinesDatasets(),
          {
            type: 'line',
            label: '流入量',
            fill: false,
            data: QInAllArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: this.colors['流入量']?.color ?? '#8080ff',
            style: `border-top-color: ${this.colors['流入量']?.color ?? '#8080ff'};`,
            fixed: true,
            showUsageGuide: true,
            yAxisID: 'y-axis-2',
          }, {
            type: 'line',
            label: '放流量',
            fill: false,
            data: QOutAllArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: this.colors['放流量']?.color ?? '#00ff7f',
            style: `border-top-color: ${this.colors['放流量']?.color ?? '#00ff7f'};`,
            fixed: true,
            showUsageGuide: true,
            yAxisID: 'y-axis-2',
          },
        ],
      };
    } else {
      const flowArray = this.getOneFromSimResult('flow');
      const wlevelArray = this.getOneFromSimResult('wlevel');

      this['$store'].commit('setMaxQAutoScale', Math.ceil(Math.max(...flowArray) / this.autoQStep) * this.autoQStep * 2);

      this['$store'].commit('setMaxHAutoScale', Math.ceil(Math.max(...wlevelArray) / 5) * 5);
      this['$store'].commit('setMinHAutoScale', Math.floor(Math.min(...wlevelArray) / 5) * 5);
      const { maxHAutoScale, minHAutoScale } = this['$store'].getters;
      if (Math.abs(maxHAutoScale - minHAutoScale) <= 5) {
        this['$store'].commit('setMaxHAutoScale', maxHAutoScale + 5);
      }

      const kurihashiDataSet: any = [];
      if (this.stationId === '17kurihashi') {
        const kurihashiKakuhoData = dataTimeArray.slice(0, 37 + 6 * this['$store'].getters.yskSpan)
          .map((date) => {
            const dateStr = date.slice(0, 10).replace(/-/g, '/');
            if (this.kurihashiKakuhoMap[dateStr]) {
              return this.kurihashiKakuhoMap[dateStr];
            }
            return 0;
          });
        kurihashiDataSet.push({
          type: 'line',
          label: '確保流量',
          fill: false,
          data: kurihashiKakuhoData,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: '#0066cc',
          borderDash: [5, 3],
          style: 'border-top-color: #0066cc;border-top-style: dotted;',
          fixed: true,
          showUsageGuide: true,
          yAxisID: 'y-axis-2',
        });
      }

      this.chartData = {
        labels: dataTimeArray.slice(0, 37 + 6 * this['$store'].getters.yskSpan),
        datasets: [
          {
            type: 'line',
            label: '水位',
            fill: false,
            data: wlevelArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: 'red',
            style: 'border-top-color: red;',
            fixed: true,
            showUsageGuide: true,
            yAxisID: 'y-axis-1',
          },
          {
            type: 'line',
            label: '流量',
            fill: false,
            data: flowArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#8080ff',
            style: 'border-top-color: #8080ff;',
            fixed: true,
            showUsageGuide: true,
            yAxisID: 'y-axis-2',
          },
          ...kurihashiDataSet,
          ...this.getLimitLinesDatasets(),
        ],
      };
    }
    this.setOptions();
  }
}
