var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.noticeData !== null)?_c('div',{class:_vm.noticeData['style'] === 1 ? 'text-container' : '',style:({
    backgroundColor: _vm.noticeData['background-color'],
    position: 'fixed',
    minHeight: '36px',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 10,
  }),attrs:{"id":"notice"}},[_c('h2',{class:_vm.noticeData['style'] === 1 ? 'scrolling-text' : '',style:(_vm.isMobile ? {
      display: 'inline-block',
      backgroundColor: _vm.noticeData['background-color'],
      color: _vm.noticeData['font-color']
    } : {
      backgroundColor: _vm.noticeData['background-color'],
      color: _vm.noticeData['font-color']
    })},[_vm._v(_vm._s(_vm.noticeData['content']))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }