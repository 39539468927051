
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Tab } from '@/views/Home.vue';
import { getCookieValue } from '@/functions';

@Component
export default class Tabs extends Vue {
  @Prop()
  private tabs!: Tab[]

  get stationId() {
    return this['$store'].getters.stationId;
  }

  get selectedTab() {
    return this['$store'].getters.tabId;
  }

  set selectedTab(value) {
    this['$store'].commit('setTabId', value);
    if (value === 'multiStations') {
      const content = this['$store'].getters.currentContent;
      if (!content) {
        throw new Error('content not find.');
      }
      this['$store'].commit('setMaxHScales', content.scales.maxHList);
      this['$store'].commit('setMinHScales', content.scales.minHList);
      this['$store'].commit('setMaxQScales', content.scales.maxQList);
    } else {
      const { scales, type } = this['$store'].getters.stationsMap[this.stationId];
      this['$store'].commit('setMaxHScales', scales.maxHList);
      this['$store'].commit('setMinHScales', scales.minHList);
      this['$store'].commit('setMaxQScales', scales.maxQList);
      this['$store'].commit('setType', type);
    }
  }

  get contents(): any[] {
    return this['$store'].getters.contents;
  }

  created() {
    const defaultTab = getCookieValue('defaultTab');
    if (this.contents.some((content) => content.id === defaultTab)) {
      this['$store'].commit('setTabId', defaultTab);
      return;
    }
    this['$store'].commit('setTabId', 'autoCalculation');
  }
}
