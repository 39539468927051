
import RepositoryFactory from '@/api/RepositoryFactory';
import { Component, Vue } from 'vue-property-decorator';

const preLoginInfoRepository = RepositoryFactory.preLoginInfo;

@Component
export default class Header extends Vue {
  noticeData: any = null;

  get isMobile() {
    return this['$store'].getters.isMobile;
  }

  get loading() {
    return this['$store'].getters.loading;
  }

  set loading(value) {
    this['$store'].commit('setLoading', value);
  }

  async created() {
    try {
      this.loading = true;
      const data = await preLoginInfoRepository.get();
      this.noticeData = data.data.notice;
      this['$store'].commit('setHeader', data.data.header);
    } catch {
      this.loading = false;
    }
    this.loading = false;
  }
}
