import RepositoryFactory from '@/api/RepositoryFactory';
import { ContentsId } from '@/types/contentsId';
import { FetchState } from '@/types/fetchState';
import { ProjectKey } from '@/types/projectKey';
import { defineStore } from 'pinia';

interface SysInfoState {
  colors: [key: { color: string, type: number}];
  contents: {
    id: ContentsId;
    isShow: boolean;
    tabName: string;
    // eslint-disable-next-line camelcase
    mesh_url?: string;
    meshMapId?: string;
    resultKey?: string;
    stations?: string[];
    subResult?: {
      enable: boolean;
      hasManual: boolean;
      manualName: string;
      manualUrl: string;
      resultKey: string;
      switchName: string;
      defaultView?: boolean;
      hasReferenceLink?: boolean;
      ReferenceLinkName?: string;
      ReferenceLinkURL?: string;
    },
  }[],
  projectKey: ProjectKey;

  // TODO: 型定義
  helpTeam: any;
  header: any;
  stationsMap: any;
}

export const useSysInfoStore = defineStore('sysInfo', {
  state: (): FetchState<SysInfoState> => ({
    data: null,
    loading: false,
    error: null,
  }),
  getters: {
    projectKey: (state) => state.data?.projectKey ?? null,
    contents: (state) => state.data?.contents ?? [],
    colors: (state) => state.data?.colors ?? null,
    helpTeam: (state) => state.data?.helpTeam ?? null,
    header: (state) => state.data?.header ?? null,
    stationsMap: (state) => state.data?.stationsMap ?? null,
    hasReferenceLink: (state) => {
      const result = state.data?.contents.find((content) => content.id === ContentsId.AutoCalculation);
      // hasReferenceLinkがfalseの場合はリンクを表示しない
      if (result?.subResult?.hasReferenceLink === false) {
        return false;
      }
      return true;
    },
  },
  actions: {
    async fetch() {
      this.loading = true;
      try {
        const { data } = await RepositoryFactory.getSysInfo.get();
        this.data = data;
      } catch (error) {
        this.error = error as Error;
      } finally {
        this.loading = false;
      }
    },
  },
});
