import {
  Component, Mixins, Prop, Watch,
} from 'vue-property-decorator';
import { Line, mixins } from 'vue-chartjs';
import { Chart } from 'chart.js';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation';

@Component({
  extends: Line,
  mixins: [mixins.reactiveProp],
})
export default class LineChart extends Mixins(mixins.reactiveProp, Line) {
  @Prop() public chartOptions!: Chart.ChartOptions;

  @Prop() public type!: 'EnRuika' | 'EnRyuryo' | 'EnChosui';

  @Watch('chartData')
  @Watch('chartOptions')
  onChartDataChanged() {
    if (this.chartData) {
      this.renderChart(this.chartData, {
        ...this.chartOptions,
        layout: {
          padding: {
            top: 10, right: 10, bottom: 10, left: 10,
          },
        },
      });
    }
  }

  mounted() {
    this.addPlugin(chartjsPluginAnnotation);
    this.renderChart(this.chartData, this.chartOptions);
    let ctx: undefined | HTMLCollectionOf<HTMLCanvasElement>;
    let height = 230;
    if (this.type === 'EnRuika') {
      ctx = document.getElementById('en-ruika')?.getElementsByTagName('canvas');
    }
    if (this.type === 'EnRyuryo') {
      height = 500;
      ctx = document.getElementById('en-ryuryo')?.getElementsByTagName('canvas');
    }
    if (this.type === 'EnChosui') {
      height = 500;
      ctx = document.getElementById('en-chosui')?.getElementsByTagName('canvas');
    }
    if (ctx !== undefined && ctx[0] !== null) {
      if (ctx[0].parentNode) {
        (ctx[0].parentNode as HTMLElement).style.height = `${height}px`;
      }
    }
  }
}
