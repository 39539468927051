import {
  Component, Mixins, Prop, Watch,
} from 'vue-property-decorator';
import { Bar, mixins } from 'vue-chartjs';
import { Chart } from 'chart.js';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation';

@Component({})
export default class BarChart extends Mixins(mixins.reactiveProp, Bar) {
  @Prop() public chartOptions!: Chart.ChartOptions;

  @Prop() public type!: 'R' | 'ChosuiRyuryo' | 'EnR';

  @Watch('chartData')
  @Watch('chartOptions')
  onChartDataChanged() {
    this.renderChart(this.chartData,
      {
        ...this.chartOptions,
        layout: {
          padding: {
            top: 10, right: 10, bottom: 10, left: 10,
          },
        },
      });
  }

  mounted() {
    this.addPlugin(chartjsPluginAnnotation);
    this.renderChart(this.chartData, this.chartOptions);
    let ctx: undefined | HTMLCollectionOf<HTMLCanvasElement>;
    let height = 300;
    let ctxs: undefined | HTMLCollectionOf<HTMLCanvasElement>[];
    if (this.type === 'ChosuiRyuryo') {
      const elements = document.getElementsByClassName('chosui-ryuryo');
      ctxs = [];
      for (let i = 0; i < elements.length; i += 1) {
        ctxs.push(elements[i].getElementsByTagName('canvas'));
      }
      height = 520;
    } else if (this.type === 'R') {
      const elements = document.getElementsByClassName('r');
      ctxs = [];
      for (let i = 0; i < elements.length; i += 1) {
        ctxs.push(elements[i].getElementsByTagName('canvas'));
      }
      height = 230;
    } else if (this.type === 'EnR') {
      ctx = document.getElementById('en-r')?.getElementsByTagName('canvas');
      height = 230;
    }
    if (ctx !== undefined && ctx[0] !== null) {
      if (ctx[0].parentNode) {
        (ctx[0].parentNode as HTMLElement).style.height = `${height}px`;
      }
    }
    if (ctxs !== undefined && ctxs.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      ctxs?.forEach((c) => {
        if (c[0] !== null && c[0].parentNode) {
          // eslint-disable-next-line no-param-reassign
          (c[0].parentNode as HTMLElement).style.height = `${height}px`;
        }
      });
    }
  }
}
