
import { useSysInfoStore } from '@/storeV2/sysInfo';
import { ProjectKey } from '@/types/projectKey';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MeshRain extends Vue {
  private show = true;

  get url() {
    this.show = false;
    this.$nextTick(
      () => {
        this.show = true;
      },
    );
    const {
      stationsMap, stationId, currentContent,
    } = this['$store'].getters;

    if (!stationsMap || !stationId) {
      return '';
    }

    // 利根川専用処理
    const sysInfoStore = useSysInfoStore();
    if (sysInfoStore.projectKey === ProjectKey.ToneFlood) {
      return `${stationsMap[stationId].mesh_link}&mode=toneflood`;
    } if (sysInfoStore.projectKey === ProjectKey.ToneLowFlow) {
      return `${stationsMap[stationId].mesh_link}&mode=tonelow-flow`;
    }

    return `${stationsMap[stationId].mesh_link}&mode=${currentContent.meshMapId}`;
  }
}
