
import {
  Component, Vue,
} from 'vue-property-decorator';
import { Chart } from 'chart.js';

@Component
export default class ChartMixin extends Vue {
  get result() {
    return this['$store'].getters.result;
  }

  get enResult() {
    return this['$store'].getters.enResult;
  }

  get basinId() {
    return this['$store'].getters.basinId;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  get targetTime() {
    return this['$store'].getters.formatTargetTime;
  }

  get yskSpan() {
    return this['$store'].getters.yskSpan;
  }

  public chartData: any = {
    datasets: [],
    labels: [],
  };
}
