
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getMaxScale } from '@/functions';
import { TabIds } from '@/views/Home.vue';
import BarChart from './BarChart';
import ChartMixin from './ChartMixin.vue';

@Component({
  components: {
    BarChart,
  },
})
export default class RChart extends Mixins(ChartMixin) {
  private chartOptions = null;

  get colors() {
    return this['$store'].getters.colors;
  }

  get loading() {
    return this['$store'].getters.loading;
  }

  get tabId(): TabIds {
    return this['$store'].getters.tabId;
  }

  get gridPitch() {
    return this['$store'].getters.gridPitch;
  }

  get isMobile() {
    return this['$store'].getters.isMobile;
  }

  get rerender() {
    return this['$store'].getters.rerender;
  }

  set rerender(value) {
    this['$store'].commit('setRerender', value);
  }

  get kouuScales() {
    return this['$store'].getters.kouuScales;
  }

  get kouuScale() {
    return this['$store'].getters.kouuScale === '自動' ? this.kouuAutoScale : this['$store'].getters.kouuScale;
  }

  get kouuAutoScale() {
    return this['$store'].getters.kouuAutoScale;
  }

  get ruikaScale() {
    return this['$store'].getters.ruikaScale === '自動' ? this['$store'].getters.ruikaAutoScale : this['$store'].getters.ruikaScale;
  }

  get ruikaAutoScale() {
    return this['$store'].getters.ruikaAutoScale;
  }

  get yskSpan() {
    return this['$store'].getters.yskSpan;
  }

  get targetTime() {
    return this['$store'].getters.targetTime;
  }

  created() {
    window.addEventListener('resize', () => {
      this.updateResult();
    });
  }

  getOneFromSimResult(key) {
    return this.result.sim_result.map((obj) => (Math.floor(obj[key]) <= -999 ? null : obj[key])).slice(0, 37 + 6 * this['$store'].getters.yskSpan);
  }

  @Watch('result')
  @Watch('yskSpan')
  @Watch('rerender')
  public async updateResult(): Promise<void> {
    if (!['autoCalculation', 'manualCalculation'].includes(this.tabId)) return;

    const RArray = this.getOneFromSimResult('R');
    const RuikaArray = this.getOneFromSimResult('Ruika');
    const dataTimeArray = this.getOneFromSimResult('datetime');
    const RMaxNum = Math.max(...RArray, 5);
    const RuikaMaxNum = Math.max(...RuikaArray, 5);

    this['$store'].commit('setKouuAutoScale', getMaxScale(RMaxNum));
    this['$store'].commit('setRuikaAutoScale', getMaxScale(RuikaMaxNum));

    this.chartData = {
      labels: dataTimeArray.slice(0, 37 + 6 * this['$store'].getters.yskSpan),
      datasets: [
        {
          type: 'bar',
          label: '雨量',
          data: RArray,
          borderWidth: 1,
          borderColor: this.colors['降雨強度']?.color ?? '#8080ff',
          style: `border-top-color: ${this.colors['降雨強度']?.color ?? '#8080ff'};`,
          fixed: true,
          yAxisID: 'y-axis-1',
        },
        {
          type: 'line',
          label: '累加',
          fill: false,
          data: RuikaArray,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: this.colors['累加雨量']?.color ?? '#00ff7f',
          style: `border-top-color: ${this.colors['累加雨量']?.color ?? '#00ff7f'};`,
          yAxisID: 'y-axis-2',
        },
      ],
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.chartOptions = {
      responsive: true,
      // maintainAspectRatio: true!this.isMobile,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem, obj) => `${obj.datasets[tooltipItem.datasetIndex].label}:${Number(tooltipItem.value).toFixed(1)}`,
        },
      },
      legend: {
        labels: {
          filter: () => false,
        },
      },
      annotation: {
        annotations: [{
          type: 'line',
          scaleID: 'x-axis-0',
          value: this.targetTime,
          borderWidth: 2,
          borderColor: 'gray',
        }],
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'HH:mm',
              },
              stepSize: this.gridPitch,
            },
            gridLines: {
              color: '#999',
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
          },
          {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MM/DD',
              },
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              fontSize: 14,
              fontColor: 'black',
            },
            gridLines: {
              color: '#999',
              drawTicks: false,
              drawBorder: false,
              drawOnChartArea: false,
              zeroLineColor: 'rgba(0, 0, 0, 0.1)',
            },
          },
        ],
        yAxes: [
          {
            afterFit(scaleInstance) {
              // eslint-disable-next-line no-param-reassign
              scaleInstance.width = 50;
            },
            id: 'y-axis-1',
            type: 'linear',
            position: 'left',
            display: true,
            gridLines: {
              color: '#999',
            },
            ticks: {
              // callback: (value) => {
              //   if (value % 1 !== 0) return null;
              //   const n = value % (this.kouuScale / 5);
              //   return n === 0 ? String(value) : null;
              // },
              stepSize: (this.kouuScale / 5),
              // maxTicksLimit: 6,
              // min: 0,
              max: this.kouuScale,
              fontSize: 14,
              fontColor: 'black',
            },
          },
          {
            afterFit(scaleInstance) {
            // eslint-disable-next-line no-param-reassign
              scaleInstance.width = 50;
            },
            id: 'y-axis-2',
            type: 'linear',
            position: 'right',
            display: true,
            afterBuildTicks: (scale) => {
              let num = this.ruikaScale;
              // eslint-disable-next-line no-param-reassign
              scale.ticks = [num];
              const step = this.ruikaScale / 5;
              while (scale.ticks.length <= 5) {
                num -= step;
                // eslint-disable-next-line no-param-reassign
                scale.ticks.push(num);
              }
            },
            gridLines: {
              color: '#999',
            },
            ticks: {
              // callback(value: any) { return ((value % 50) === 0) ? value : ''; },
              maxTicksLimit: 6,
              min: 0,
              max: this.ruikaScale,
              fontSize: 14,
              fontColor: 'black',
            },
          },
        ],
      },
    };
  }
}
