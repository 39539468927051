var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex"},[(!_vm.isMobile)?_c('div',{staticClass:"setting-component pr-4 pt-3"},[_c('GraphSetting')],1):_vm._e(),(_vm.tableInfo)?_c('div',{staticClass:"main-component pb-5"},[_c('div',{staticClass:"container overflow-x-auto"},[_c('div',{staticClass:"float-right"},[_vm._v("予測時刻："+_vm._s(_vm.tableInfo.currentTime))]),_c('table',{staticStyle:{"border-collapse":"collapse","width":"100%","white-space":"nowrap"}},[_c('thead',[_c('tr',{staticClass:"caption"},[(_vm.tableInfo.hasClass)?_c('th',{attrs:{"rowspan":"4"}},[_vm._v(" 管理 ")]):_vm._e(),_c('th',{attrs:{"rowspan":"4"}},[_vm._v(" ダム ")]),_c('th',{attrs:{"colspan":"4"}},[_vm._v(" 雨量 ")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(" 流入量 ")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(" 貯水位 ")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" 操作 ")])]),_vm._m(0),_vm._m(1),_vm._m(2)]),_c('tbody',{staticClass:"body-2 text-center"},[_vm._l((_vm.tableInfo.stations),function(station,index){return _c('tr',{key:station.id,staticClass:"text-center data-table-row"},[(_vm.getFirstClassIndex(station.class) == index && _vm.tableInfo.hasClass)?_c('td',{attrs:{"rowspan":_vm.getClassDuplicateCount(station.class)}},[_vm._v(" "+_vm._s(station.class)+" ")]):_vm._e(),_c('td',{on:{"click":function($event){return _vm.damClickHandler(station.id)}}},[_c('span',{staticClass:"station-link"},[_vm._v(_vm._s(station.name))])]),_c('td',{staticClass:"pr-2",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.roundToNDecimalPlaces(station.bsnActRain.currentVal, 1))+" ")]),_c('td',{staticClass:"pr-2",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(station.bsnPreRain.bsnPreRainLimit)+" / "+_vm._s(station.bsnPreRain.bsnPreRainConTime)+"hr ")]),_c('td',{staticClass:"pr-2",style:(_vm.getColumnStyle(
                  _vm.getColumnColor(
                    // backgroundColorがキーになるのでここはbackgroundColorでok
                    station.bsnPreRain.items.find(item => item.type == 'msmgd').backgroundColor
                  ).foregroundColor,
                  _vm.getColumnColor(
                    station.bsnPreRain.items.find(item => item.type == 'msmgd').backgroundColor
                  ).backgroundColor
                )),attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.roundToNDecimalPlaces( station.bsnPreRain.items.find(item => item.type == 'msmgd').value, 1 ))+" ")]),_c('td',{staticClass:"pr-2",style:(_vm.getColumnStyle(
                  _vm.getColumnColor(
                    station.bsnPreRain.items.find(item => item.type == 'gsmgd').backgroundColor
                  ).foregroundColor,
                  _vm.getColumnColor(
                    station.bsnPreRain.items.find(item => item.type == 'gsmgd').backgroundColor
                  ).backgroundColor
                )),attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.roundToNDecimalPlaces( station.bsnPreRain.items.find(item => item.type == 'gsmgd').value, 1 ))+" ")]),_c('td',{staticClass:"pr-2",style:(_vm.getColumnStyle(
                  _vm.getColumnColor(
                    station.inflow.currentVal.backgroundColor,
                  ).foregroundColor,
                  _vm.getColumnColor(
                    station.inflow.currentVal.backgroundColor
                  ).backgroundColor
                )),attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.roundToNDecimalPlaces( station.inflow.currentVal.value, 2 ))+" ")]),_c('td',{staticClass:"pr-2",style:(_vm.getColumnStyle(
                  _vm.getColumnColor(station.inflow.predictMax.backgroundColor).foregroundColor,
                  _vm.getColumnColor(station.inflow.predictMax.backgroundColor).backgroundColor
                )),attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.roundToNDecimalPlaces( station.inflow.predictMax.value, 2 ))+" ")]),_c('td',{style:(_vm.getColumnStyle(
                  _vm.getColumnColor(station.inflow.predictMax.backgroundColor).foregroundColor,
                  _vm.getColumnColor(station.inflow.predictMax.backgroundColor).backgroundColor
                ))},[_vm._v(" "+_vm._s(_vm.displayEstimatedTime(station.inflow.predictMax.estimatedTime))+" ")]),_c('td',{staticClass:"pr-2",style:(_vm.getColumnStyle(
                  _vm.getColumnColor(station.waterlevel.currentVal.backgroundColor).foregroundColor,
                  _vm.getColumnColor(station.waterlevel.currentVal.backgroundColor).backgroundColor
                )),attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.roundToNDecimalPlaces( station.waterlevel.currentVal.value, 2 ))+" ")]),_c('td',{staticClass:"pr-2",style:(_vm.getColumnStyle(
                  _vm.getColumnColor(station.waterlevel.predictMax.backgroundColor).foregroundColor,
                  _vm.getColumnColor(station.waterlevel.predictMax.backgroundColor).backgroundColor
                )),attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.roundToNDecimalPlaces( station.waterlevel.predictMax.value, 2 ))+" ")]),_c('td',{style:(_vm.getColumnStyle(
                  _vm.getColumnColor(station.waterlevel.predictMax.backgroundColor).foregroundColor,
                  _vm.getColumnColor(station.waterlevel.predictMax.backgroundColor).backgroundColor
                ))},[_vm._v(" "+_vm._s(_vm.displayEstimatedTime(station.waterlevel.predictMax.estimatedTime))+" ")]),_c('td',[_vm._v(" "+_vm._s(station.operation.preControl ? '要︎' : '')+" ")]),_c('td',[_vm._v(" "+_vm._s(station.operation.floodControl ? '⚪︎' : '')+" ")])])}),_vm._m(3),_c('tr',{staticClass:"text-left"},[_c('td',{staticClass:"border-none",attrs:{"colspan":"2"}}),_c('td',{style:({
              backgroundColor: _vm.getColumnColor('基準降雨量以上').backgroundColor
            })}),_c('td',{staticClass:"border-none",attrs:{"colspan":"2"}},[_vm._v("基準降雨量以上")]),_c('td',{style:({
              backgroundColor: _vm.getColumnColor('計画高水流量以上').backgroundColor
            })}),_c('td',{staticClass:"border-none",attrs:{"colspan":"2"}},[_vm._v("計画高水流量以上")]),_c('td',{style:({
              backgroundColor: _vm.getColumnColor('洪水時最高水位以上').backgroundColor
            })}),_c('td',{staticClass:"border-none",attrs:{"colspan":"4"}},[_vm._v("洪水時最高水位以上")])]),_c('tr',{staticClass:"text-left"},[_c('td',{staticClass:"border-none",attrs:{"colspan":"2"}}),_c('td',{style:({
              backgroundColor: _vm.getColumnColor('洪水貯留準備水位または平常時最高貯水位未満').backgroundColor
            })}),_c('td',{staticClass:"border-none",attrs:{"colspan":"2"}},[_vm._v("基準降雨量未満")]),_c('td',{style:({
              backgroundColor: _vm.getColumnColor('洪水量以上').backgroundColor
            })}),_c('td',{staticClass:"border-none",attrs:{"colspan":"2"}},[_vm._v("洪水量以上")]),_c('td',{style:({
              backgroundColor: _vm.getColumnColor('異常洪水時防災操作開始水位以上').backgroundColor
            })}),_c('td',{staticClass:"border-none",attrs:{"colspan":"4"}},[_vm._v("異常洪水時防災操作開始水位以上")])]),_c('tr',{staticClass:"text-left"},[_c('td',{staticClass:"border-none",attrs:{"colspan":"5"}}),_c('td',{style:({
              backgroundColor: _vm.getColumnColor('洪水貯留準備水位または平常時最高貯水位未満').backgroundColor
            })}),_c('td',{staticClass:"border-none",attrs:{"colspan":"2"}},[_vm._v("洪水量未満")]),_c('td',{style:({
              backgroundColor: _vm.getColumnColor('洪水貯留準備水位または平常時最高貯水位以上').backgroundColor
            })}),_c('td',{staticClass:"border-none",attrs:{"colspan":"4"}},[_vm._v("洪水貯留準備水位または平常時最高貯水位以上")])]),_c('tr',{staticClass:"text-left"},[_c('td',{staticClass:"border-none",attrs:{"colspan":"8"}}),_c('td',{style:({
              backgroundColor: _vm.getColumnColor('洪水貯留準備水位または平常時最高貯水位未満').backgroundColor
            })}),_c('td',{staticClass:"border-none",attrs:{"colspan":"4"}},[_vm._v("洪水貯留準備水位または平常時最高貯水位未満")])])],2)]),_vm._m(4),_c('p',[_vm._v("※2 事前放流の基準降雨量を超過し、貯水位の低下が必要な場合に”要”と表示されます。")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"caption"},[_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 現時刻(累加) ")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(" 予測※1 ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 現時刻 ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 予測(最大) ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 発生時刻 ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 現時刻 ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 予測(最高) ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 発生時刻 ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 事前放流※2 ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 洪水調節 ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"caption"},[_c('th',[_vm._v(" 基準雨量 ")]),_c('th',[_vm._v(" MSM-G ")]),_c('th',[_vm._v(" GSM-G ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"caption"},[_c('th',{attrs:{"width":"90"}},[_vm._v(" [mm] ")]),_c('th',{attrs:{"width":"90"}},[_vm._v(" [mm] ")]),_c('th',{attrs:{"width":"90"}},[_vm._v(" [mm] ")]),_c('th',{attrs:{"width":"90"}},[_vm._v(" [mm] ")]),_c('th',{attrs:{"width":"90"}},[_vm._v(" [m^3/s] ")]),_c('th',{attrs:{"width":"90"}},[_vm._v(" [m^3/s] ")]),_c('th',{attrs:{"width":"90"}},[_vm._v(" [時間後] ")]),_c('th',{attrs:{"width":"90"}},[_vm._v(" [EL.m] ")]),_c('th',{attrs:{"width":"90"}},[_vm._v(" [EL.m] ")]),_c('th',{attrs:{"width":"90"}},[_vm._v(" [時間後] ")]),_c('th',[_vm._v(" - ")]),_c('th',[_vm._v(" - ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"pt-3 border-none"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v("※1 事前放流ガイドラインに基づく予測降雨量("),_c('a',{attrs:{"href":"https://jizen.river.go.jp/","target":"_blank"}},[_vm._v("https://jizen.river.go.jp/)")]),_vm._v("、事前放流の実施基準の降雨継続期間内")])
}]

export { render, staticRenderFns }