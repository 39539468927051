
import { Component, Vue } from 'vue-property-decorator';
import GraphSetting from '../GraphSetting.vue';

@Component({
  components: {
    GraphSetting,
  },
})
export default class DamPredictionTable extends Vue {
  get isMobile(): boolean {
    return window.innerWidth <= 1200;
  }

  get selectedClass() {
    return this['$store'].getters.selectedClass;
  }

  get tableInfo() {
    const info = this['$store'].getters.tableInfo;
    if (!info) return null;
    let stations = [...info.stations]
      .map((station) => ({
        ...station,
        class: this.getStationClass(station.id),
        name: this.getStationName(station.id),
      }));
    if (this.selectedClass !== '全部') {
      stations = stations.filter((station) => station.class === this.selectedClass);
    }
    stations.sort((a, b) => {
      if (a.class < b.class) {
        return -1;
      }
      if (a.class > b.class) {
        return 1;
      }
      return 0;
    });
    return { ...this['$store'].getters.tableInfo, stations };
  }

  get colorMap() {
    return this['$store'].getters.tableInfo.colorList;
  }

  getColumnColor(key: string) {
    const foregroundColor = this.colorMap['foregroundColor'][key] ?? '#000000';
    const backgroundColor = this.colorMap['backgroundColor'][key] ?? this.colorMap[key];
    return {
      foregroundColor, backgroundColor,
    };
  }

  damClickHandler(stationId: string) {
    this['$store'].commit('setTabId', 'autoCalculation');
    this['$router'].replace({ query: { stationId } });
  }

  getColumnStyle(color: string, backgroundColor: string) {
    return {
      color,
      backgroundColor,
    };
  }

  roundToNDecimalPlaces(num: string | number, n: number) {
    if (num === -999) {
      return '-';
    }
    const parsedNum = typeof num === 'string' ? parseFloat(num) : num;
    if (Number.isNaN(parsedNum)) {
      throw new Error('値が数値ではありません。');
    }
    const multiplier = 10 ** n;
    const roundedNum = Math.round(parsedNum * multiplier) / multiplier;
    return roundedNum.toFixed(n);
  }

  getStationName(stationId) {
    const { stationsMap } = this['$store'].getters;
    return stationsMap[stationId]?.name ?? '';
  }

  getStationClass(stationId) {
    const { stationsMap } = this['$store'].getters;
    return stationsMap[stationId]?.class ?? '';
  }

  /**
   * stationsの中で対象のclass名を持つstationの数を取得
   * @param className
   */
  getClassDuplicateCount(className: string) {
    return this.tableInfo.stations.reduce((count, station) => {
      if (station.class === className) {
        return count + 1;
      }
      return count;
    }, 0);
  }

  /**
   * stationsの中で対象のclass名を持つ最初のindexを取得
   * @param className
   */
  getFirstClassIndex(className: string) {
    return this.tableInfo.stations.findIndex((station) => {
      if (station.class === className) {
        return true;
      }
      return false;
    });
  }

  /**
   * x時間y分の形にする
   * 2023/09/21 仕様変更 1時間以上の場合は分を表示しない https://redmine.riskma.jp/issues/305
   * @param estimatedTime 分単位で値をもっている
   */
  displayEstimatedTime(estimatedTime: number) {
    const hour = Math.floor(estimatedTime / 60);
    const min = Math.floor(estimatedTime % 60);
    if (hour) {
      return `${hour}時間後`;
    }
    if (min) {
      return `${min}分後`;
    }
    return '-';
  }
}
