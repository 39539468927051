import Repository from './Repository';

export default {
  login(payload: {
    username: string,
    password: string,
  }): Promise<any> {
    return Repository.post('api/login', payload, {
      withCredentials: true,
    });
  },
  refresh(): Promise<any> {
    return Repository.post('api/refresh', {
      withCredentials: true,
    });
  },
  logout(): Promise<any> {
    return Repository.delete('api/logout', {
      withCredentials: true,
    });
  },
};
