import sysInfoRepository from './sysInfoRepository';
import preLoginInfoRepository from './preLoginInfoRepository';
import resultRepository from './resultRepository';
import authRepository from './authRepository';
import lastTimeRepository from './lastTimeRepository';
import damSupportInfoRepository from './damSupportInfoRepository';
import damDischargeInfoRepository from './damDischargeInfoRepository';
import tableInfoRepository from './tableInfoRepository';
import kurihashiKakuhoRepository from './kurihashiKakuhoRepository';

const RepositoryFactory = {
  getSysInfo: sysInfoRepository,
  getResult: resultRepository,
  lastTime: lastTimeRepository,
  auth: authRepository,
  preLoginInfo: preLoginInfoRepository,
  damSupportInfo: damSupportInfoRepository,
  damDischargeInfo: damDischargeInfoRepository,
  getTableInfo: tableInfoRepository,
  getKurihashiKakuho: kurihashiKakuhoRepository,
};

export default RepositoryFactory;
