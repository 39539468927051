import Repository from './Repository';

const resource = 'api/tableInfo';

export default {
  get({ pathParams, queryParams }:
    {
      pathParams: {
        basinId: string,
      },
      queryParams: {
        tgttime: string,
      }
    }): Promise<any> {
    return Repository.get(`${resource}/${pathParams.basinId}?tgttime=${queryParams.tgttime}`);
  },
};
