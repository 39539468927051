/* eslint-disable camelcase */
import Repository from '@/api/Repository';
import { ref } from 'vue';

type State = {
  kokiDecision: boolean,
  kokiDecisionComment: string,
  Title_condition: string,
  Title_evaluation: string,
  evaluation_number: number,
  condition1: string,
  evaluation1: string,
  condition2: string,
  evaluation2: string,
  condition3: string,
  evaluation3: string,
  condition4: string,
  evaluation4: string,
  comment_number: number,
  comment1: string,
  comment2: string
} | null;

type RequestParams = {
  basinId: string;
  stationId: string;
  tgttime: string | number;
}

export const kokiFloodReleaseSupportInfo = ref<State>(null);

const resource = 'api/kokiFloodReleaseSupportInfo';
export const loadKokiFloodReleaseSupportInfo = async ({ basinId, stationId, tgttime }: RequestParams) => {
  const url = `${resource}/${basinId}/${stationId}?tgttime=${tgttime.toString()}`;
  try {
    const response = await Repository.get<State>(url);
    kokiFloodReleaseSupportInfo.value = response.data;
  } catch (error) {
    console.error(error);
    kokiFloodReleaseSupportInfo.value = null;
  }
};
