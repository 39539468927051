
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getMaxScale } from '@/functions';
import LineChart from './LineChart';
import ChartMixin from './ChartMixin.vue';

@Component({
  components: {
    LineChart,
  },
})
export default class EnRuikaChart extends Mixins(ChartMixin) {
  private chartOptions = null;

  get rerender() {
    return this['$store'].getters.rerender;
  }

  set rerender(value) {
    this['$store'].commit('setRerender', value);
  }

  get gridPitch() {
    return this['$store'].getters.enGridPitch;
  }

  get ruikaScales() {
    return this['$store'].getters.ruikaScales;
  }

  get yskSpan() {
    return this['$store'].getters.enYskSpan;
  }

  get ruikaScale() {
    return this['$store'].getters.ruikaScale === '自動' ? this['$store'].getters.ruikaAutoScale : this['$store'].getters.ruikaScale;
  }

  get targetTime() {
    return this['$store'].getters.enTargetTime;
  }

  get ensampleDisplayMember() {
    return this['$store'].getters.ensampleDisplayMember;
  }

  mounted() {
    this.updateResult();
  }

  getOneFromResult(result, key) {
    return result.map((obj) => (Math.floor(obj[key]) <= -999 ? null : obj[key]));
  }

  @Watch('tab')
  @Watch('yskSpan')
  @Watch('enResult')
  @Watch('rerender')
  @Watch('ensampleDisplayMember')
  public async updateResult() {
    const top = this.getOneFromResult(this.enResult[0], 'Ruika');
    const middle = this.getOneFromResult(this.enResult[Math.floor(this.enResult.length / 2)], 'Ruika');
    const bottom = this.getOneFromResult(this.enResult[this.enResult.length - 1], 'Ruika');

    let ruikaDatasets = this.enResult.map((result) => ({
      label: '累加雨量',
      fill: false,
      data: result.map((obj) => obj.Ruika),
      pointRadius: 0,
      borderWidth: 2,
      borderColor: '#c0c0ff',
      style: 'border-top-color: #c0c0ff;',
      notShowLable: true,
    }));
    if (this.ensampleDisplayMember === 'all') {
      ruikaDatasets = ruikaDatasets.filter((_result, i) => i !== 0
              && i !== Math.floor(this.enResult.length / 2)
              && i !== this.enResult.length - 1);
    } else if (this.ensampleDisplayMember === 'top') {
      ruikaDatasets = ruikaDatasets.filter((_result, i) => i !== 0
              && i !== Math.floor(this.enResult.length / 2)
              && i !== this.enResult.length - 1 && i < 5);
    } else if (this.ensampleDisplayMember === 'bot') {
      ruikaDatasets = ruikaDatasets.filter((_result, i) => i !== 0
              && i !== Math.floor(this.enResult.length / 2)
              && i !== this.enResult.length - 1 && this.enResult.length - 1 - 10 < i);
    } else {
      ruikaDatasets = [];
    }

    if (ruikaDatasets.length > 0) {
      ruikaDatasets[0].notShowLable = false;
    }

    let maxNum = Math.max(...top, ...middle, ...bottom);
    ruikaDatasets.forEach((dataset) => {
      maxNum = Math.max(maxNum, ...dataset.data);
    });
    this['$store'].commit('setRuikaAutoScale', getMaxScale(maxNum));

    const dataTimeArray = this.enResult[0].map((obj) => obj.datetime);
    this.chartData = {
      labels: dataTimeArray.slice(0, 7 + this.yskSpan),
      datasets: [
        {
          label: '上位累加雨量',
          fill: false,
          data: top,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: '#f00',
          style: 'border-top-color: red;',
        },
        {
          label: '中位累加雨量',
          fill: false,
          data: middle,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: '#00f',
          style: 'border-top-color: blue;',
        },
        {
          label: '下位累加雨量',
          fill: false,
          data: bottom,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: '#0f0',
          style: 'border-top-color: green;',
        },
        ...ruikaDatasets,
      ],
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem, obj) => `${obj.datasets[tooltipItem.datasetIndex].label}:${Number(tooltipItem.value).toFixed(1)}`,
        },
      },
      legend: {
        labels: {
          filter: () => false,
        },
      },
      annotation: {
        annotations: [{
          type: 'line',
          scaleID: 'x-axis-0',
          value: this.targetTime,
          borderWidth: 2,
          borderColor: 'gray',
        }],
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'HH:mm',
              },
              stepSize: this.gridPitch,
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
            gridLines: {
              color: '#999',
            },
          },
          {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MM/DD',
              },
            },
            gridLines: {
              color: '#999',
              drawTicks: false,
              drawBorder: false,
              drawOnChartArea: false,
              zeroLineColor: 'rgba(0, 0, 0, 0.1)',
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
          },
        ],
        yAxes: [
          {
            afterFit(scaleInstance) {
              // eslint-disable-next-line no-param-reassign
              scaleInstance.width = 40;
            },
            gridLines: {
              color: '#999',
            },
            ticks: {
              // callback(value: any) { return ((value % 50) === 0) ? value : ''; },
              // maxTicksLimit: 6,
              min: 0,
              // max: this.ruikaScale,
              max: this.ruikaScale,
              // callback: (value) => {
              //   if (value % 1 !== 0) return null;
              //   const n = value % (this.ruikaScale / 5);
              //   return n === 0 ? value : null;
              // },
              stepSize: (this.ruikaScale / 5),
              // stepSize: 1,
              fontSize: 14,
              fontColor: 'black',
            },
          },
          {
            position: 'right',
            display: true,
            gridLines: {
              color: '#999',
            },
            ticks: {
              callback() { return ''; },
              fontSize: 14,
              fontColor: 'black',
            },
          },
        ],
      },
    };
  }
}
