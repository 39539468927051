import Repository from './Repository';

const resource = 'api/damDischargeInfo';

export default {
  get({ pathParams, queryParams }:
    {
      pathParams: {
        basinId: string,
        stationId: string,
      },
      queryParams: {
        tgttime: string | number,
      }
    }): Promise<any> {
    return Repository.get(`${resource}/${pathParams.basinId}/${pathParams.stationId}?tgttime=${queryParams.tgttime}`);
  },
};
