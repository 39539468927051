
import { Component, Vue, Watch } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import Header from '@/components/organisms/Header.vue';
import Notice from '@/components/organisms/Notice.vue';
import Tabs from '@/components/molecules/Tabs.vue';
import DamPrediction from '@/components/organisms/tabs/DamPrediction.vue';
import DamPredictionTable from '@/components/organisms/tabs/DamPredictionTable.vue';
import DamPredictionMobile from '@/components/organisms/tabs/DamPredictionMobile.vue';
import DamEnsample from '@/components/organisms/tabs/DamEnsample.vue';
import DamEnsampleMobile from '@/components/organisms/tabs/DamEnsampleMobile.vue';
import DamCompareWater from '@/components/organisms/tabs/DamCompareWater.vue';
import DamCompareWaterMobile from '@/components/organisms/tabs/DamCompareWaterMobile.vue';
import MeshRain from '@/components/organisms/tabs/MeshRain.vue';
import GraphSetting from '@/components/organisms/GraphSetting.vue';
import Spinner from 'vue-spinner/src/ScaleLoader.vue';
import { getCookieValue } from '@/functions';
import { loadKokiFloodReleaseSupportInfo } from '@/state/kokiFloodReleaseSupportInfo';
import axios from 'axios';
import { useSysInfoStore } from '@/storeV2/sysInfo';
import { ProjectKey } from '@/types/projectKey';
import { ContentsId } from '@/types/contentsId';

const sysInfoRepository = RepositoryFactory.getSysInfo;
const tableInfoRepository = RepositoryFactory.getTableInfo;
const lastTimeRepository = RepositoryFactory.lastTime;
const resultRepository = RepositoryFactory.getResult;
const authRepository = RepositoryFactory.auth;
const dischargeInfoRepository = RepositoryFactory.damDischargeInfo;
const damSupportInfoRepository = RepositoryFactory.damSupportInfo;
const kurihashiKakuhoRepository = RepositoryFactory.getKurihashiKakuho;

export type TabIds
  = 'autoCalculation' | 'ensembleCalculation' | 'meshMap' | 'manualCalculation' | 'multiStations' | 'statusChart';

export type Tab = {
  id: TabIds;
  title: string;
  component: any;
  style?: any;
}

@Component({
  components: {
    Header,
    Notice,
    Tabs,
    DamPrediction,
    DamPredictionMobile,
    GraphSetting,
    Spinner,
  },
})
export default class Home extends Vue {
  private skipWatch = true;

  marginTop = '0px';

  get loading() {
    return this['$store'].getters.loading;
  }

  set loading(value) {
    this['$store'].commit('setLoading', value);
  }

  get header() {
    return this['$store'].getters.header;
  }

  get isJizenHouryu() {
    return this['$store'].getters.isJizenHouryu;
  }

  get showDrawer() {
    return this['$store'].getters.showDrawer;
  }

  set showDrawer(value) {
    this['$store'].commit('setShowDrawer', value);
  }

  get showEnsample() {
    const sysInfoStore = useSysInfoStore();
    return sysInfoStore.projectKey === ProjectKey.Nagano;
  }

  get tabs(): Tab[] {
    return this.contents.map((content) => {
      const base = { id: content.id, title: content.tabName };
      if (content.id === 'autoCalculation') {
        return { component: this.isMobile ? DamPredictionMobile : DamPrediction, ...base };
      } if (content.id === 'ensembleCalculation') {
        return { component: this.isMobile ? DamEnsampleMobile : DamEnsample, ...base };
      } if (content.id === 'manualCalculation') {
        return { component: this.isMobile ? DamPredictionMobile : DamPrediction, ...base };
      } if (content.id === 'multiStations') {
        return { component: this.isMobile ? DamCompareWaterMobile : DamCompareWater, ...base };
      } if (content.id === 'meshMap') {
        return { component: MeshRain, style: { height: '80vh' }, ...base };
      } if (content.id === 'statusChart') {
        return { component: DamPredictionTable, ...base };
      }
      throw new Error('unexpected error occurred.');
    });
  }

  get basinId() {
    return this['$store'].getters.basinId;
  }

  get ensampleTabStatusStatus() {
    return this['$store'].getters.ensampleTabStatusStatus;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  set stationId(value) {
    this['$store'].commit('setStationId', value);
  }

  get targetTime() {
    return this['$store'].getters.formatTargetTime;
  }

  get enTargetTime() {
    return this['$store'].getters.enFormatTargetTime;
  }

  get yskSpan() {
    return this['$store'].getters.yskSpan;
  }

  get tabId(): TabIds {
    return this['$store'].getters.tabId;
  }

  set tabId(value) {
    this['$store'].commit('tabId', value);
  }

  get autoLoad() {
    return this['$store'].getters.autoLoad;
  }

  set autoLoad(value) {
    this['$store'].commit('setAutoLoad', value);
  }

  get contents(): any[] {
    return this['$store'].getters.contents;
  }

  get currentStationIds(): string[] {
    return this['$store'].getters.currentStationIds;
  }

  get isMobile(): boolean {
    return window.innerWidth <= 1200;
  }

  /**
   * 初期stationIdを取得
   * 優先度
   * 1.cookieのbasinIdSettingsの対応するbasinIdの値
   * 2.cookieのobserIdの値
   * 3.sysInfoのstationsから配列の最初のstationId
   */
  getDefaultStationId(currentStationIds: string[], basinId: string): string {
    const jsonBasinIdSettings = getCookieValue('basinIdSettings');
    if (jsonBasinIdSettings) {
      if (jsonBasinIdSettings) {
        const basinIdSettings = JSON.parse(
          jsonBasinIdSettings.replace(/'/g, '"').replace(/\\"/g, '"').replace(/\\054/g, ',').slice(1, -1),
        );
        // basinIdが一致する設定を取得
        const basinIdSetting = basinIdSettings.find((setting) => setting['basinId'] === basinId);
        // obserIdが実際にstationsに存在するか
        if (basinIdSetting && currentStationIds.find((id) => id === basinIdSetting.obserId)) {
          return basinIdSetting.obserId;
        }
      }
    }
    const cookieObserId = getCookieValue('obserId');
    if (cookieObserId) return cookieObserId;

    return currentStationIds[0];
  }

  @Watch('autoLoad')
  async autoLoadWatcher() {
    if (this.autoLoad && !this['$store'].getters.intervalId) {
      const intervalId = setInterval(async () => {
        const { data: lastTime } = await lastTimeRepository.get({
          pathParams: {
            basinId: this.basinId,
            stationId: this.stationId,
          },
          queryParams: {
            isEn: false,
          },
        });
        this['$store'].commit('setLatestTargetTime', lastTime.lastTime);
        this['$store'].commit('setTargetTime', lastTime.lastTime);

        if (this.showEnsample) {
          const { data: enLastTime } = await lastTimeRepository.get({
            pathParams: {
              basinId: this.basinId,
              stationId: this.stationId,
            },
            queryParams: {
              isEn: true,
            },
          });
          this['$store'].commit('setEnLatestTargetTime', enLastTime.lastTime);
          this['$store'].commit('setEnTargetTime', enLastTime.lastTime);
        }
      }, 60000);
      // token refresh
      await authRepository.refresh();
      this['$store'].commit('setIntervalId', intervalId);
    }
    if (!this.autoLoad && this['$store'].getters.intervalId) {
      clearInterval(this['$store'].getters.intervalId);
      this['$store'].commit('setIntervalId', null);
    }
  }

  @Watch('stationId')
  @Watch('tabId')
  loadStorage() {
    if (this.tabId === 'multiStations') {
      this['$store'].commit('setKouuScale', Number(localStorage.getItem('compare-water-KouuScale'))
        || '自動');
      this['$store'].commit('setRuikaScale', Number(localStorage.getItem('compare-water-RuikaScale'))
        || '自動');
      this['$store'].commit('setMaxHScale', Number(localStorage.getItem('compare-water-MaxHScale'))
        || '自動');
      this['$store'].commit('setMinHScale', Number(localStorage.getItem('compare-water-MinHScale'))
        || '自動');
      this['$store'].commit('setMaxQScale', Number(localStorage.getItem('compare-water-MaxQScale'))
      || '自動');
      return;
    }
    this['$store'].commit('setKouuScale', Number(localStorage.getItem(`${this.basinId}-${this.stationId}-KouuScale`))
      || '自動');
    this['$store'].commit('setRuikaScale', Number(localStorage.getItem(`${this.basinId}-${this.stationId}-RuikaScale`))
      || '自動');
    this['$store'].commit('setMaxHScale', Number(localStorage.getItem(`${this.basinId}-${this.stationId}-MaxHScale`))
      || '自動');
    this['$store'].commit('setMinHScale', Number(localStorage.getItem(`${this.basinId}-${this.stationId}-MinHScale`))
      || '自動');
    this['$store'].commit('setMaxQScale', Number(localStorage.getItem(`${this.basinId}-${this.stationId}-MaxQScale`))
      || '自動');
  }

  @Watch('basinId')
  @Watch('stationId')
  @Watch('targetTime')
  @Watch('isJizenHoryu')
  async updateResultByWatcher() {
    if (this.skipWatch) return;
    const sysInfoStore = useSysInfoStore();
    await sysInfoStore.fetch();
    this.updateResult();
    this.updateTable();
  }

  async updateTable() {
    const hasStatusChartContent = this.contents.some((content) => content.id === 'statusChart');
    if (hasStatusChartContent) {
      const { data: tableInfo } = await tableInfoRepository.get({
        pathParams: { basinId: this.basinId },
        queryParams: { tgttime: this.targetTime },
      });
      this['$store'].commit('setTableInfo', tableInfo);
    }
  }

  async updateResult() {
    if (!this.stationId) return;
    if (this.basinId && this.targetTime) {
      this.loading = true;
      try {
        loadKokiFloodReleaseSupportInfo({
          basinId: this.basinId, stationId: this.stationId, tgttime: this.targetTime,
        });
        const { data: result } = await damSupportInfoRepository.get({
          pathParams: {
            basinId: this.basinId,
            stationId: this.stationId,
          },
          queryParams: {
            tgttime: this.targetTime,
            isEn: this.tabId === 'ensembleCalculation',
          },
        });
        this['$store'].commit('setSupportInfoList', result);
      } catch (error) {
        this.loading = false;
        this['$store'].commit('setSupportInfoList', []);
      }
      try {
        const { data: dischargeInfo } = await dischargeInfoRepository.get({
          pathParams: {
            basinId: this.basinId,
            stationId: this.stationId,
          },
          queryParams: {
            tgttime: this.targetTime,
          },
        });
        if (Object.keys(dischargeInfo).length === 0) {
          this['$store'].commit('setDischargeInfo', null);
        } else {
          this['$store'].commit('setDischargeInfo', dischargeInfo);
        }
      } catch (error) {
        this.loading = false;
        this['$store'].commit('setDischargeInfo', null);
      }
      if (this.tabId === 'multiStations') {
        await this.getSuiiResultList();
      }
      try {
        const { data: result } = await resultRepository.get({
          pathParams: {
            basinId: this.basinId,
            stationId: this.stationId,
          },
          queryParams: {
            tgttime: this.targetTime,
          },
        });
        this['$store'].commit('setResult', result);
      } catch (error) {
        this.loading = false;
        this['$store'].commit('setResult', null);
      }
      this.loading = false;
    }
  }

  async getSuiiResultList() {
    const stationIds = this['$store'].getters.currentStationIds;
    const resultList: any = [];
    for (let i = 0; i < stationIds.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const data = await resultRepository.get({
        pathParams: {
          basinId: this['$store'].getters.currentContent.resultKey,
          stationId: stationIds[i],
        },
        queryParams: {
          tgttime: this.targetTime,
        },
      });
      resultList.push(data.data);
    }
    this['$store'].commit('setSuiiResultList', resultList);
  }

  // TODO: updateResultと同じでwatchを分離した方がよさそう, 複数回呼び出される？
  @Watch('stationId')
  @Watch('enTargetTime')
  async updateEnResult() {
    if (this.tabId !== 'ensembleCalculation') return;
    if (this.stationId && this.basinId && this.enTargetTime && ['11okususobana', '12susobana', '16matsukawa'].includes(this.stationId)) {
      try {
        this['$store'].commit('setEnsampleTabStatus', 'loading');
        const { data: enResult } = await resultRepository.get({
          pathParams: {
            basinId: this.basinId,
            stationId: this.stationId,
          },
          queryParams: {
            tgttime: this.enTargetTime,
            isEn: true,
          },
        });
        // 連想配列になっている為配列に
        const array = Object.keys(enResult).map((key) => (enResult[key]));
        // 累加雨量でソート
        array.sort(
          (res: any[], res2: any[]) => res2[res2.length - 1].Ruika - res[res.length - 1].Ruika,
        );
        this['$store'].commit('setEnResult', array);
        if (array.length > 0) {
          this['$store'].commit('setEnsampleTabStatus', 'show');
        } else {
          this['$store'].commit('setEnsampleTabStatus', 'hide');
        }
      } catch (error) {
        console.log('error');
        this['$store'].commit('setEnsampleTabStatus', 'hide');
      }
    }
  }

  @Watch('tabId')
  async setUpContentsByWatcher() {
    // resultKeyが存在する場合のみ
    if (!this['$store'].getters.currentContent?.resultKey) return;
    this.skipWatch = true;
    this.loading = true;
    await this.setUpContents();
    if (this.tabId !== 'ensembleCalculation' && this.tabId !== 'meshMap') {
      await this.updateResult();
    } if (this.tabId === 'ensembleCalculation') {
      await this.updateEnResult();
    } if (this.tabId === 'statusChart') {
      await this.updateTable();
    }
    this.loading = false;
    this.skipWatch = false;
  }

  async setUpContents() {
    if (this.isMobile) {
      this['$store'].commit('setIsMobile', true);
    }

    try {
      let { stationId } = this;
      const sysInfoStore = useSysInfoStore();
      await sysInfoStore.fetch();

      if (sysInfoStore.projectKey === ProjectKey.ToneLowFlow) {
        const { data: { kakuho_date_map: kurihashiKakuhoMap } } = await kurihashiKakuhoRepository
          .get();
        this['$store'].commit('setKurihashiKakuhoMap', kurihashiKakuhoMap);
      }
      this['$store'].commit('setHelpTeam', sysInfoStore?.helpTeam ?? null);
      this['$store'].commit('setHeader', sysInfoStore.header);
      this['$store'].commit('setContents', sysInfoStore.contents ?? []);
      this['$store'].commit('setColors', sysInfoStore.colors);

      // basinIdは事前放流のtoggleによって初期化
      const content = sysInfoStore.contents.find((it) => it.id === ContentsId.AutoCalculation);
      let basinId = this['$store'].getters.currentContent?.resultKey;
      if (content) {
        // 操作方法のtoggleの初期化
        const isJizenHoryu = content.subResult?.defaultView ?? true;
        this['$store'].commit('setIsJizenHouryu', isJizenHoryu);
        if (!isJizenHoryu) {
          basinId = this['$store'].getters.currentContent?.subResult?.resultKey;
        }
      }

      // TODO: 利根川（低水）専用対応
      // if (this.tabId === 'multiStations') {
      //   const { multiStations: { stations } } = sysInfo.basinStationsMap['02tonelowflow'];
      //   const [station] = stations;
      //   stationId = station;
      // }
      if (this.tabId !== 'ensembleCalculation') {
        stationId = this.getDefaultStationId(this.currentStationIds, basinId);
      } else if (this.currentStationIds.length > 0) {
        stationId = this.currentStationIds.length && this.currentStationIds[0];
      } else {
        throw new Error('stationIds not found.');
      }

      const { data: lastTime } = await lastTimeRepository.get({
        pathParams: { basinId, stationId },
        queryParams: { isEn: false },
      });

      this['$store'].commit('setStationsMap', sysInfoStore.stationsMap);
      const { scales, type } = sysInfoStore.stationsMap[stationId];
      this['$store'].commit('setMaxHScales', scales.maxHList);
      this['$store'].commit('setMinHScales', scales.minHList);
      this['$store'].commit('setMaxQScales', scales.maxQList);
      this['$store'].commit('setType', type);
      if (this.showEnsample) {
        this['$store'].commit('setEnTargetTime', null);
      }
      this['$store'].commit('setTargetTime', lastTime.lastTime);
      this['$store'].commit('setLatestTargetTime', lastTime.lastTime);
      this['$store'].commit('setBasinId', basinId);

      if (this.showEnsample) {
        const { data: enLastTime } = await lastTimeRepository.get({
          pathParams: {
            basinId,
            stationId,
          },
          queryParams: {
            isEn: true,
          },
        });
        this['$store'].commit('setEnLatestTargetTime', enLastTime.lastTime);
        this['$store'].commit('setEnTargetTime', enLastTime.lastTime);
      }
      this['$store'].commit('setStationId', stationId);
      this.loadStorage();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this['$store'].commit('setResult', null);
        // 不正なトークン投げられた場合ログイン画面に
        if (error.response?.status === 401) {
          console.error('invalid token.');
          this['$router'].push('/login');
        } else {
          console.log('unexpected error occurred.');
          this['$router'].push('/login');
        }
      } else if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error('unexpected error occurred.');
      }
    }
  }

  @Watch('loading')
  async setMarginTop() {
    setTimeout(() => {
      const element = document.getElementById('notice');
      if (element !== null) {
        const height = element.clientHeight;
        this.marginTop = `${height}px`;
      }
    }, 500);
    if (!this.loading) {
      const { stationId } = this['$route'].query;
      if (stationId) {
        this.stationId = stationId;
        this.loading = true;
        await this.updateResult();
        this['$router'].replace('/');
        this.loading = false;
      }
    }
  }

  @Watch('header')
  changeTitle() {
    document.title = this.header.name;
  }

  async created() {
    await this.setUpContents();
    await this.updateResult();
    await this.updateEnResult();

    const sysInfoStore = useSysInfoStore();
    if (sysInfoStore.projectKey === ProjectKey.ToneLowFlow) {
      this['$store'].commit('setYskSpan', 48);
    }
    this.loading = false;
    this.skipWatch = false;
  }
}
