
import { Component, Vue } from 'vue-property-decorator';
import html2canvas from 'html2canvas';

@Component
export default class App extends Vue {
  private isPrintReady = false;

  get printMode() {
    return this['$store'].getters.printMode;
  }

  set printMode(value) {
    this['$store'].commit('setPrintMode', value);
  }

  get tabId() {
    return this['$store'].getters.tabId;
  }

  created() {
    window.onbeforeprint = async () => {
      this.printMode = true;
      await this.$nextTick(async () => {
        const selector = this.tabId === 'ensembleCalculation' ? '.main-component-en' : '.main-component';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const canvas = await html2canvas(document.querySelector(selector), { scale: '2' });
        this.isPrintReady = true;
        const img = document.getElementById('print_image') as HTMLImageElement;
        img.src = canvas.toDataURL();
      });
    };
    window.onafterprint = () => {
      const img = document.getElementById('print_image') as HTMLImageElement;
      img.src = '';
      this.isPrintReady = false;
      this.printMode = false;
      this.$nextTick(() => {
        this['$store'].commit('setRerender', true);
        this.$nextTick(() => {
          this['$store'].commit('setRerender', false);
        });
      });
    };
  }
}
