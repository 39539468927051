
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DamSupport extends Vue {
  get infos() {
    return this['$store'].getters.supportInfoList;
  }

  get basinId() {
    return this['$store'].getters.basinId;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  get tabId() {
    return this['$store'].getters.tabId;
  }

  get targetTime() {
    return this['$store'].getters.formatTargetTime;
  }

  get isJizenHouryu() {
    return this['$store'].getters.isJizenHouryu;
  }

  /**
   * @description infoのlevelから緊急度を取得する
   */
  getEmergencyLevel(level: number) {
    return level < 10 ? level : Math.floor(level / 10);
  }

  getTitleClass(index) {
    const info = this.infos[index];
    const emergencyLevel = this.getEmergencyLevel(info.level);
    if (emergencyLevel === 0) {
      return 'subtitle-1';
    }
    return 'subtitle-1 red--text text--darken-1';
  }

  getTextClass(index) {
    const info = this.infos[index];
    const emergencyLevel = this.getEmergencyLevel(info.level);
    if (emergencyLevel <= 1) {
      return '';
    }
    return 'red--text text--darken-1';
  }
}
