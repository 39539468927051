
import { Component, Vue, Watch } from 'vue-property-decorator';
import { useSysInfoStore } from '@/storeV2/sysInfo';
import GraphSetting from '../GraphSetting.vue';
import DamSupport from '../DamSupport.vue';
import DamTable from '../DamTable.vue';
import RCompareWaterChart from '../../molecules/RCompareWaterChart.vue';
import RyuryoCompareWaterChart from '../../molecules/RyuryoCompareWaterChart.vue';

@Component({
  components: {
    GraphSetting,
    DamSupport,
    DamTable,
    RCompareWaterChart,
    RyuryoCompareWaterChart,
  },
})
export default class DamCompareWater extends Vue {
  sysInfoStore = useSysInfoStore();

  get dischargeInfo() {
    return this['$store'].getters.dischargeInfo;
  }

  get printMode() {
    return this['$store'].getters.printMode;
  }

  get result() {
    return this['$store'].getters.result;
  }

  get basinId() {
    return this['$store'].getters.basinId;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  get targetTime() {
    return this['$store'].getters.formatTargetTime;
  }

  get stationName() {
    const { stationsMap } = this['$store'].getters;
    const { stationId } = this['$store'].getters;
    if (!stationsMap || !stationId) {
      return '';
    }
    return stationsMap[stationId].name;
  }

  get type() {
    return this['$store'].getters.type;
  }

  get figure() {
    return this.sysInfoStore.stationsMap[this.stationId]?.figure ?? '';
  }

  get displayTargetTime() {
    const { formatTargetTime } = this['$store'].getters;
    if (!formatTargetTime) {
      return '';
    }
    return `${formatTargetTime.slice(0, 4)}年${formatTargetTime.slice(4, 6)}月${formatTargetTime.slice(6, 8)}日`
        + ` ${formatTargetTime.slice(8, 10)}時${formatTargetTime.slice(10, 12)}分`;
  }
}
