import { use01NaganoEns } from '@/functions';
import Repository from './Repository';

const resource = 'api/result';

export default {
  get({ pathParams, queryParams }:
    {
      pathParams: {
        basinId: string,
        stationId: string,
      },
      queryParams: {
        tgttime: string,
        isEn?: boolean,
      }
    }): Promise<any> {
    return Repository.get(`${resource}/${use01NaganoEns(queryParams.isEn) ? '01nagano_ens' : pathParams.basinId}/${pathParams.stationId}?tgttime=${queryParams.tgttime}&isEn=${queryParams.isEn ?? false}`);
  },
};
