
import { Component, Vue } from 'vue-property-decorator';
import { kokiFloodReleaseSupportInfo } from '@/state/kokiFloodReleaseSupportInfo';

@Component
export default class KokiFloodReleaseSupportInfo extends Vue {
  get info() {
    return kokiFloodReleaseSupportInfo.value;
  }
}
