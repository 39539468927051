
import { Component, Mixins, Watch } from 'vue-property-decorator';
import LineChart from './LineChart';
import ChartMixin from './ChartMixin.vue';

@Component({
  components: {
    LineChart,
  },
})
export default class EnRyuryoChart extends Mixins(ChartMixin) {
  private chartOptions = null;

  get rerender() {
    return this['$store'].getters.rerender;
  }

  set rerender(value) {
    this['$store'].commit('setRerender', value);
  }

  get gridPitch() {
    return this['$store'].getters.enGridPitch;
  }

  get yskSpan() {
    return this['$store'].getters.enYskSpan;
  }

  get maxQScales() {
    return this['$store'].getters.maxQScales;
  }

  get maxQScale() {
    return this['$store'].getters.maxQScale === '自動' ? this['$store'].getters.maxQAutoScale : this['$store'].getters.maxQScale;
  }

  get ensampleDisplayMember() {
    return this['$store'].getters.ensampleDisplayMember;
  }

  get targetTime() {
    return this['$store'].getters.enTargetTime;
  }

  get autoQStep() {
    const { stationsMap } = this['$store'].getters;
    return stationsMap[this.stationId].scales.autoQStep;
  }

  mounted() {
    this.updateResult();
  }

  getOneFromResult(result, key) {
    return result.map((obj) => (Math.floor(obj[key]) <= -999 ? null : obj[key]));
  }

  @Watch('tab')
  @Watch('yskSpan')
  @Watch('enResult')
  @Watch('rerender')
  @Watch('ensampleDisplayMember')
  public async updateResult() {
    const { type } = this['$store'].getters;
    const dataTimeArray = this.enResult[0].map((obj) => obj.datetime);

    if (Number(type) === 7) {
      const topQInAllArray = this.getOneFromResult(this.enResult[0], 'Qin_all');
      // 2022/01/26 放流量非表示
      const topQOutAllArray = this.getOneFromResult(this.enResult[0], 'Qout_all');

      const midQInAllArray = this.getOneFromResult(this.enResult[Math.floor(this.enResult.length / 2)], 'Qin_all');
      const midQOutAllArray = this.getOneFromResult(this.enResult[Math.floor(this.enResult.length / 2)], 'Qout_all');

      const botQInAllArray = this.getOneFromResult(this.enResult[this.enResult.length - 1], 'Qin_all');
      const botQOutAllArray = this.getOneFromResult(this.enResult[this.enResult.length - 1], 'Qout_all');

      let qInDatasets = this.enResult.map((result) => ({
        label: '流入量',
        fill: false,
        data: result.map((obj) => obj.Qin_all),
        pointRadius: 0,
        borderWidth: 2,
        borderColor: '#c0cfff',
        style: 'border-top-color: #c0cfff;',
        notShowLable: true,
      }));

      // let qOutDatasets = this.enResult.map((result) => ({
      //   label: '放流量',
      //   fill: false,
      //   data: result.map((obj) => obj.Qout_all),
      //   pointRadius: 0,
      //   borderWidth: 2,
      //   borderColor: '#c0cf8f',
      //   style: 'border-top-color: #c0cf8f;',
      //   notShowLable: true,
      // }));

      if (this.ensampleDisplayMember === 'all') {
        qInDatasets = qInDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1);
        // qOutDatasets = qOutDatasets.filter((_result, i) => i !== 0
        //         && i !== Math.floor(this.enResult.length / 2)
        //         && i !== this.enResult.length - 1);
      } else if (this.ensampleDisplayMember === 'top') {
        qInDatasets = qInDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1 && i < 5);
        // qOutDatasets = qOutDatasets.filter((_result, i) => i !== 0
        //         && i !== Math.floor(this.enResult.length / 2)
        //         && i !== this.enResult.length - 1 && i < 5);
      } else if (this.ensampleDisplayMember === 'bot') {
        qInDatasets = qInDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1 && this.enResult.length - 1 - 10 < i);
        // qOutDatasets = qOutDatasets.filter((_result, i) => i !== 0
        //         && i !== Math.floor(this.enResult.length / 2)
        //         && i !== this.enResult.length - 1 && this.enResult.length - 1 - 10 < i);
      } else {
        qInDatasets = [];
        // qOutDatasets = [];
      }

      if (qInDatasets.length > 0) {
        qInDatasets[0].notShowLable = false;
      }

      let maxNum = Math.max(...topQInAllArray, ...midQInAllArray, ...botQInAllArray);
      qInDatasets.forEach((dataset) => {
        maxNum = Math.max(maxNum, ...dataset.data);
      });
      this['$store'].commit('setMaxQAutoScale', Math.ceil(maxNum / this.autoQStep) * this.autoQStep * 2);

      this.chartData = {
        labels: dataTimeArray.slice(0, 7 + this.yskSpan),
        datasets: [
          {
            label: '上位流入量',
            fill: false,
            data: topQInAllArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#f00',
            style: 'border-top-color: #f00;',
          },
          // {
          //   label: '上位放流量',
          //   fill: false,
          //   data: topQOutAllArray,
          //   pointRadius: 0,
          //   borderWidth: 2,
          //   borderColor: '#f88',
          //   style: 'border-top-color: #f88;',
          // },
          {
            label: '中位流入量',
            fill: false,
            data: midQInAllArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#00f',
            style: 'border-top-color: #00f;',
          },
          // {
          //   label: '中位放流量',
          //   fill: false,
          //   data: midQOutAllArray,
          //   pointRadius: 0,
          //   borderWidth: 2,
          //   borderColor: '#88f',
          //   style: 'border-top-color: #88f;',
          // },
          {
            label: '下位流入量',
            fill: false,
            data: botQInAllArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#080',
            style: 'border-top-color: #080;',
          },
          // {
          //   label: '下位放流量',
          //   fill: false,
          //   data: botQOutAllArray,
          //   pointRadius: 0,
          //   borderWidth: 2,
          //   borderColor: '#0f0',
          //   style: 'border-top-color: #0f0;',
          // },
          ...qInDatasets,
          // ...qOutDatasets,
        ],
      };
    } else {
      const topFlowArray = this.getOneFromResult(this.enResult[0], 'flow');

      const midFlowArray = this.getOneFromResult(this.enResult[Math.floor(this.enResult.length / 2)], 'flow');

      const botFlowArray = this.getOneFromResult(this.enResult[this.enResult.length - 1], 'flow');

      let flowDatasets = this.enResult.map((result) => ({
        label: '流量',
        fill: false,
        data: this.getOneFromResult(result, 'flow'),
        pointRadius: 0,
        borderWidth: 2,
        borderColor: '#c0cfff',
        style: 'border-top-color: #c0cfff;',
        notShowLable: true,
      }));

      if (this.ensampleDisplayMember === 'all') {
        flowDatasets = flowDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1);
        flowDatasets[0]['notShowLable'] = false;
      } else if (this.ensampleDisplayMember === 'top') {
        flowDatasets = flowDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1 && i < 5);
        flowDatasets[0]['notShowLable'] = false;
      } else if (this.ensampleDisplayMember === 'bot') {
        flowDatasets = flowDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1 && this.enResult.length - 1 - 10 < i);
        flowDatasets[0]['notShowLable'] = false;
      } else {
        flowDatasets = [];
      }

      let maxNum = Math.max(...topFlowArray, ...midFlowArray, ...botFlowArray);
      flowDatasets.forEach((dataset) => {
        maxNum = Math.max(maxNum, ...dataset.data);
      });
      this['$store'].commit('setMaxQAutoScale', Math.ceil(maxNum / this.autoQStep) * this.autoQStep * 2);

      this.chartData = {
        labels: dataTimeArray.slice(0, 7 + this.yskSpan),
        datasets: [
          {
            label: '上位流量',
            fill: false,
            data: topFlowArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#f00',
            style: 'border-top-color: #f00;',
          },
          {
            label: '中位流量',
            fill: false,
            data: midFlowArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#00f',
            style: 'border-top-color: #00f;',
          },
          {
            label: '下位流量',
            fill: false,
            data: botFlowArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#080',
            style: 'border-top-color: #080;',
          },
          ...flowDatasets,
        ],
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem, obj) => `${obj.datasets[tooltipItem.datasetIndex].label}:${Number(tooltipItem.value).toFixed(2)}`,
        },
      },
      annotation: {
        annotations: [{
          type: 'line',
          scaleID: 'x-axis-0',
          value: this.targetTime,
          borderWidth: 2,
          borderColor: 'gray',
        }],
      },
      legend: {
        labels: {
          filter: () => false,
        },
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'HH:mm',
              },
              stepSize: this.gridPitch,
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
            gridLines: {
              color: '#999',
            },
          },
          {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MM/DD',
              },
            },
            gridLines: {
              drawTicks: false,
              drawBorder: false,
              drawOnChartArea: false,
              zeroLineColor: 'rgba(0, 0, 0, 0.1)',
              color: '#999',
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
          },
        ],
        yAxes: [{
          afterFit(scaleInstance) {
            // eslint-disable-next-line no-param-reassign
            scaleInstance.width = 40;
          },
          gridLines: {
            color: '#999',
          },
          ticks: {
            maxTicksLimit: 8,
            min: 0,
            max: this.maxQScale,
            fontSize: 14,
            fontColor: 'black',
          },
        },
        {
          position: 'right',
          display: true,
          gridLines: {
            color: '#999',
          },
          ticks: {
            callback() { return ''; },
            fontSize: 14,
            fontColor: 'black',
          },
        },
        ],
      },
    };
  }
}
