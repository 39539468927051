
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class DamDischargeInfoTable extends Vue {
  get dischargeInfo() {
    return this['$store'].getters.dischargeInfo;
  }

  get basinId() {
    return this['$store'].getters.basinId;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  // eslint-disable-next-line class-methods-use-this
  get displayItems() {
    return [];
  }

  get type() {
    return this['$store'].getters.type;
  }

  // 上のテーブル用
  private getTextClass1(num1, num2) {
    return num1 < num2 ? 'red--text' : '';
  }

  // 下のテーブル用
  private getTextClass2(num) {
    return num > 0 ? 'red--text' : 'light-blue--text';
  }

  // 1970-01-01T00:00:00 -> 01/01 00:00
  private formatTime(time) {
    return `${time.slice(5, 7)}/${time.slice(8, 10)} ${time.slice(11, 16)}`;
  }

  lessThanOrEqual(a, b) {
    return a <= b;
  }
}
